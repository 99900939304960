import React, { useEffect, useState } from 'react';

import Lottie from 'react-lottie';

import * as animationData from 'assets/animations/main-square.json';

const LaunchingSoon = () => {
  const [height, setHeight] = useState('100vh');

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="launching-soon" style={{ height }}>
      <div className="launching-soon__inner d-flex align-items-center justify-content-center d-xl-block">
        <div className="animated-box">
          <Lottie options={defaultOptions} height={216} width={216} />
        </div>
        <div className="texts-box">
          <h3 className="page-subtitle mb-2 mb-md-4 mb-xl-6">SOFTWARE APPLICATIONS</h3>
          <h1 className="font-anton page-title">Launching soon</h1>
        </div>
      </div>
    </div>
  );
};

export default LaunchingSoon;
